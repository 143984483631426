import React from 'react';
import styled from 'styled-components';
import Container from '../__Container';

import Banner from '../__Banner';
import Section from './_Section';
import Data from '../data/_serviceData';
import BannerImg from 'imgs/1d1d/pc/banner.service.png';

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fafafa;
`;

export default function Service() {
  return (
    <Container>
      <Banner banner={BannerImg} h1="" h2s={['kindergarten internet', 'service platform']} />
      <Sections>
        {Data.map((s, idx) => {
          return <Section {...s} reverse={idx % 2 !== 0} key={s.title} />;
        })}
      </Sections>
    </Container>
  );
}
