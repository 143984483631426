import React from 'react';
import styled from 'styled-components';
import Icon from 'imgs/1d1d/pc/icon.circle.logo.png';

const Wrapper = styled.section`
  padding: 0.4rem 0.36rem 0.64rem;
  background: #fff;
  &:not(:last-child) {
    margin-bottom: 0.28rem;
  }
  .imgWrapper {
    display: flex;
    height: 2.04rem;
    .txt {
      z-index: 80;
      background-color: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 4.16rem;
      > h2 {
        font-size: 0.28rem;
        color: #636363;
        margin-bottom: 0.1rem;
        font-weight: bold;
      }
      > h3 {
        font-size: 0.18rem;
        color: #969696;
      }
    }
    .pic {
      position: relative;
      z-index: 90;
      height: 2.04rem;
      .ico {
        z-index: 99;
        width: 0.84rem;
        position: absolute;
        top: 50%;
        right: -0.42rem;
        transform: translateY(-50%);
      }
      .img {
        width: 2.6rem;
        height: 100%;
      }
    }
  }
  .con {
    font-size: 0.22rem;
    color: #636363;
    text-indent: 0.44rem;
    line-height: 0.47rem;
    margin-top: 0.4rem;
  }
`;
export default function Banner({ title, subTitle, img, content }) {
  return (
    <Wrapper>
      <div className="imgWrapper">
        <div className="pic">
          <img className="ico" src={Icon} alt="圆形图标" />
          <img data-aos="fade-right" className="img" src={img} alt="Banner" />
        </div>
        <div data-aos="fade-left" className="txt">
          <h2>{title}</h2>
          <h3>{subTitle}</h3>
        </div>
      </div>
      <p className="con">{content}</p>
    </Wrapper>
  );
}
